<template>
  <b-card no-body class="p-0">
    <b-card-header>
      <div>
        <b-card-title class="mb-25">
          {{ title }}
        </b-card-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body no-body>
      <b-card
        no-body
        v-for="(item, index) in data"
        :key="index"
        class="card-todo p-0"
        @click="
          $router.push({
            name: checkAuthorizeRole('chapter-tasks.show'),
            params: {
              trainingId: item.from.training_id,
              chapterId: item.from.id,
            },
          })
        "
      >
        <b-card-body class="p-1">
        <strong>{{ item.from.training.title }}</strong>
        <div>Pertemuan: {{ item.from.title }}</div>
        <div>Batas: {{ item.end_at | moment("dddd, Do MMM YYYY HH:mm") }}</div>
        </b-card-body>
      </b-card>
    </b-card-body>
  </b-card>
</template>
  
<script>
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { checkAuthorizeRole } from "@/auth/utils";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatPickr,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => ({}),
    },
  },
  data() {
    return {
      rangePicker: ["2022-11-01", "2022-12-10"],
    };
  },
  mounted() {},
  methods: {
    checkAuthorizeRole,
  },
};
</script>

<style scoped>
.card-todo {
  background-color: rgb(255, 217, 217) !important;
  cursor: pointer;
}
.card-todo:hover {
  background-color: rgb(248, 154, 154) !important;
}
</style>