<template>
  <section id="dashboard">
    <!-- Statistik Kelas  -->
    <b-row>
      <!-- Jumlah Pelatihan -->
      <b-col lg="3">
        <b-card>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <i class="font-medium-5 ti ti-school" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">Pelatihan Diikuti</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ data.joinedTrainingCount }} Pelatihan
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>

      <!-- Jumlah Diterima -->
      <b-col lg="3">
        <b-card>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <i class="font-medium-5 ti ti-file" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">Tugas Dikerjakan</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ data.finishedTaskCount }} Tugas
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>

      <!-- Jumlah Persetujuan -->
      <b-col lg="3">
        <b-card>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <i class="font-medium-5 ti ti-x" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">Belum Dikerjakan</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ data.unFinishedTaskCount }} Tugas
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>

      <!-- Jumlah Lulus -->
      <b-col lg="3">
        <b-card>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <i class="font-medium-5 ti ti-check" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">Sertifikat</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ data.certificateCount }} Pelatihan
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col xl="8">
        <dashboard-horizontal-bar-chart
          v-if="unFinishedTasksChart.labels.length"
          title="Progres Tugas Pelatihan"
          :data="unFinishedTasksChart"
          :options="ChartOptions"
        />
      </b-col>
      <b-col xl="4">
        <component-todo title="Daftar Tugas" :data="data.todos" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide,
  BSpinner,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCardText,
} from "bootstrap-vue";
import { $themeColors } from "@themeConfig";

import axios from "axios";
import { ref, onMounted } from "@vue/composition-api";

import { reqGetUserDashboard } from "@/api/user/dashboard";
import DashboardHorizontalBarChart from "./DashboardHorizontalBarChart.vue";
import ComponentTodo from "./Todo.vue";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    BSpinner,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,
    ComponentTodo,
    DashboardHorizontalBarChart,
  },
  data() {
    return {
      data: {
        staffCount: 0,
        trainerCount: 0,
        userCount: 0,
        trainingCount: 0,
        unFinishedTasks: [],
      },
      ChartOptions: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: "top",
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                zeroLineColor: chartColors.grid_line_color,
                borderColor: "transparent",
                color: chartColors.grid_line_color,
                drawTicks: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                min: 0,
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
      unFinishedTasksChart: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: $themeColors.info,
            borderColor: "transparent",
            barThickness: 15,
          },
        ],
      },
    };
  },
  async created() {
    await reqGetUserDashboard().then((res) => {
      this.data = res.data.data;
      const trainingLabels = [];
      const tasks = [];
      const finishedTasks = [];
      const unfinishedTasks = [];
      this.data.unFinishedTasks.forEach((element) => {
        trainingLabels.push(element.title);
        tasks.push(element.tasks_count);
        finishedTasks.push(element.finished_tasks_count);
        unfinishedTasks.push(element.unfinished_tasks_count);
      });
      this.unFinishedTasksChart = {
        labels: trainingLabels,
        datasets: [
          {
            data: tasks,
            backgroundColor: $themeColors.info,
            borderColor: "transparent",
            barThickness: 15,
            label: "Total Tugas",
          },
          {
            data: finishedTasks,
            backgroundColor: "lightgreen",
            borderColor: "transparent",
            barThickness: 15,
            label: "Sudah Dikerjakan",
          },
          {
            data: unfinishedTasks,
            backgroundColor: "#DADADA",
            borderColor: "transparent",
            barThickness: 15,
            label: "Belum Dikerjakan",
          },
        ],
      };
    });
  },
  setup() {
    const token = localStorage.getItem("accessToken");
    const newsImages = ref([]);

    function onImgError(e) {
      console.log("error", e);
    }
    return {
      newsImages,
      onImgError,
    };
  },
};
</script>

<style lang="scss">
.card-img-overlay {
  padding: 0 !important;
}

.news-slide-content {
  border-radius: 0.428rem !important;

  img {
    height: 350px;
    border-radius: 0.428rem !important;
    object-fit: cover;
  }
}
</style>
